<template>
  <div class="menuBox" style="transition: all 1s ease 0s;">
    <div class="menuBox-header">
      <a @click="closeCart()" class="closeBtn">
        <button id="close" class="is-active">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </a>
      <!-- <img src="../svg/icon-cart.png" alt="" style="width:7%" width="7%"> -->
      <h2>SHOPPING BAG</h2>
    </div>
    <div v-if="data.items.length == 0" class="cart-empty">
     
      <h2>Your Shopping Bag is Empty</h2>
      <p>Looks like you have no item in your shopping bag.</p>
      <!-- <a @click="changeRoute('/')" class="btn mt20">Continue Shopping</a> -->
      <a @click="changeRoute('/')" class="f-bs" data-id="1731">
        <svg class="f-bs-arrow" viewBox="0 0 6 10">
          <path
            d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
          />
        </svg>
        <svg class="f-bs-arrow" viewBox="0 0 6 10">
          <path
            d="M0,9.9C1.4,8.3,2.9,5.6,3.4,5C2.9,4.4,1.4,1.7,0,0.1L0.1,0C1.5,1.6,5.8,4.8,6,5 c-0.2,0.2-4.5,3.4-5.9,5L0,9.9z"
          />
        </svg>
        <span class="f-bs-line"></span>
        <i class="f-bs-label">Continue Shopping</i>
      </a>
    </div>
    <div v-if="data.items.length != 0" class="menuBox-body">
      <div class="product-grid">
        <div v-for="(items, index) in data.items" :key="index" class="product-details">
          <div
            v-for="(img,i) in items.images"
            :key="i"
            v-if="img.default == 'Yes'"
            class="product-image"
          >
            <div v-if="items.price" class="product-qty">{{items.product_quantity}}</div>
            <img :src="$store.state.file_url + img.image[0].src + '?alt=media'" alt />
          </div>
          <div class="product-content">
            <div class="product-name">
              <h4>{{items.name}}</h4>
              <a @click="removeCart(items)">
                <span class="material-icons">delete_outline</span>
              </a>
            </div>
            <div v-if="items.collections" style="margin-bottom: 2%;">
              <h4 class="colName">{{trimName(items.collections[0].slug)}}</h4>
            </div>
            <div
              v-if="items && items.variants_definitions && items.variants_definitions[0].value"
              style="margin-bottom: 2%; display: flex;"
            >
              <h4
                class="colName"
                v-for="(variants,ind) in items.selected_variants"
                :key="ind"
              >{{variants + ', ' }}</h4>
            </div>
            <div v-if="items.price && currency == 'USD'" class="product-price">
              <span>{{convertCurrency(items.price)}}</span>
              {{currency}}
            </div>
            <div v-if="items.price  && currency == 'PKR'" class="product-price">
              <span>{{formatMoney(items.price)}}</span>
              {{currency}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="menuBox-footer" v-if="data.items.length != 0">
      <div style="padding:3%"></div>
      <div v-if="data.items.length != 0" class="checkout">
        <div class="checkoutPrice">
          <div class="price">
            <p>Total</p>
            <h4
              v-if="currency == 'USD'"
            >{{currency}} {{convertCurrency(data.calculate.sub_total)}}/-</h4>
            <h4 v-if="currency == 'PKR'">{{currency}} {{formatMoney(data.calculate.sub_total)}}/-</h4>
          </div>
        </div>
        <div class="checkoutbtn">
          <a @click="changeRoute('/page/cartdetail')" class="btn">checkout</a>
        </div>
      </div>
      <div class="checkoutbtn">
        <a @click="closeCart()" class="btn cont-btn">
          <h4>Continue Shopping</h4>
        </a>
      </div>
      <!-- <div class="checkout">
                <div class="checkoutbtn">
                    <a href="#"><img src="../svg/Icon_Explore.png" alt="" style="width:50%" width="50%"></a>
                </div>
                <div class="checkoutPrice">
                    <a @click="closeCart()"><h4>Continue Shopping</h4></a>
                </div>
                <div v-if="userInfo.id" class="checkoutbtn">
                    <a href="/#/page/myaccount"><img src="../svg/Icon_User.png" alt="" style="width:50%" width="50%"></a>
                </div>
                <div v-if="!userInfo.id" class="checkoutbtn">
                    <a @click="openSigninPop()"><img src="../svg/Icon_User.png" alt="" style="width:50%" width="50%"></a>
                </div>
      </div>-->
    </div>
  </div>
</template>


<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      data: {},
      userInfo: {},
      product: {
        product_quantity: 1
      },
      fact: 3,
      currency: ""
    };
  },
  watch: {
    "$route.params": {
      handler(newValue, preValue) {
        this.init();
        this.closeCart();
      },
      immediate: true
    }
  },

  created() {
    this.init();
    this.factorial(3);
    if (localStorage.getItem("currency")) {
      this.currency = JSON.parse(localStorage.getItem("currency"));
    }
  },
  methods: {
    factorial(x) {
      setTimeout(() => {
        if (x < 0) return;
        if (x === 0) {
          x = 3;
        }
        x * this.factorial(x - 1);
        this.init();
      }, 1000);
    },
    init() {
      this.$store.dispatch("Store");
      this.data = [];
      this.data = this.$store.state.cart;
      this.userInfo = this.$store.state.customer;
    },
    changeRoute(path) {
      if (document.getElementById("cartContainer")) {
        document.getElementById("cartContainer").classList.remove("openCart");
      }
      this.$router.push({ path: path });
    },
    recursive() {
      setTimeout(() => {
        this.init();
      }, 1000);
    },
    removeCart(product) {
      var params = { action: "remove", product, product };
      this.$store.dispatch("Cart", params);
      this.$store.dispatch("Store");
      this.data = this.$store.state.cart;
    },
    formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(
          (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString();
        let j = i.length > 3 ? i.length % 3 : 0;
        if (document.getElementById("convertedPrice")) {
          document.getElementById("convertedPrice").innerHTML =
            negativeSign +
            (j ? i.substr(0, j) + thousands : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
            (decimalCount
              ? decimal +
                Math.abs(amount - i)
                  .toFixed(decimalCount)
                  .slice(2)
              : "");
        }
        return (
          negativeSign +
          (j ? i.substr(0, j) + thousands : "") +
          i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
          (decimalCount
            ? decimal +
              Math.abs(amount - i)
                .toFixed(decimalCount)
                .slice(2)
            : "")
        );
      } catch (e) {
        // console.log(e)
      }
    },
    trimName(name) {
      if (name)
        return name
          .replace(new RegExp("_", "g"), " ")
          .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
          .toUpperCase();
    },
    closeCart() {
      if (document.getElementById("cartContainer")) {
        document.getElementById("cartContainer").classList.remove("openCart");
      }
    },
    openSigninPop() {
      if (document.getElementById("signinContainer")) {
        document
          .getElementById("signinContainer")
          .classList.add("showSigninContainer");
      }
    },
    convertCurrency(amount) {
      var price = false;
      var c = false;
      if (localStorage.getItem("currencyValue")) {
        c = JSON.parse(localStorage.getItem("currencyValue"));
      }
      price = Math.round(amount / c);
      return price;
    }
  }
};
</script>

<style scoped>
/* cart menu */

#close {
  /* right: calc(100vw / 5 * 1); */
  top: 0.9vw;
  width: 0.8vw;
  height: 1.25vw;
  z-index: 95;
  display: block;
  cursor: pointer;
  position: fixed;
}
#close span {
  height: 1px;
  width: 100%;
  display: block;
  position: relative;
  background-color: #1f1f1f;
  transition: all 0.4s ease-out;
}
#close.is-active span:nth-child(1) {
  /* transform: rotate(135deg) translate(0.1vw, -0.20vw); */
  transform: rotate(135deg) translate(-0.3vw, 0vw);
}
#close.is-active span:nth-child(2) {
  opacity: 0;
}
#close.is-active span:nth-child(3) {
  /* transform: rotate(45deg ) translate(.1vw, 0vw); */
  transform: rotate(45deg) translate(0.3vw, 0vw);
}

.menuBox {
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 380px;
  height: auto;
  z-index: 99999;
  height: 100vh;
  z-index: 99999;
  background-color: #f9f9f9;
  transition: all 0.5s ease;
  transform: translateX(100%);
  top: 0;
  right: 0;
  z-index: 999;
}

.menuBox-header {
  height: auto;
  /* border-bottom: 1px solid #D1D1D1; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 6vh;
}

.menuBox .menuBox-header h2 {
  font-weight: 500;
  color: #3e3e3e;
  font-size: 18px;
  font-family: "Saol Display";
}

.menuBox .menuBox-header img {
  margin-right: 10px;
}

.menuBox .menuBox-header .closeBtn {
  position: absolute;
  top: 9px;
  left: 5%;
  height: 25px;
  width: 25px;
  background: #f9f9f9;
  border-radius: 21px;
  color: #272222;
  display: flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  /* border: 1px solid;  */
  /* width: 30px; */
}

.menuBox-body {
  height: calc(100% - 100px - 80px);
  display: flex;
  padding: 15px;
  width: 100%;
  /* align-items: center;
    justify-content: center; */
}

.menuBox-body .product-image {
  width: 100px;
  height: 100px;
  margin-right: 20px;
  margin-left: 10px;
}

.menuBox-body .product-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
  top: -10px;
  position: relative;
}

.menuBox-body .product-grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: scroll;
}

.menuBox-body .product-grid::-webkit-scrollbar {
  display: none;
}

.menuBox-body .product-details {
  display: flex;
  align-items: flex-start;
  margin: 10px 0;
}

.menuBox-body .product-details .product-content {
  width: 100%;
}

.menuBox-body .product-name {
  display: flex;
  width: 98%;
  align-items: flex-start;
  justify-content: space-between;
}

.menuBox-body .product-name h4 {
  line-height: 24px;
  font-weight: 400;
  margin: 0 0 5px 0;
}

.colName {
  font-size: 12px;
  letter-spacing: 1px;
}
.menuBox-body .product-name a {
  font-weight: 600;
  font-size: 18px;
  margin-top: 0px;
}

.menuBox-body .product-name a span {
  color: #e00b0b;
}

.menuBox-body .product-price {
  color: #af9aa4;
  font-size: 14px;
  font-weight: 500;
}

.menuBox-body .product-varient {
  display: flex;
  align-items: flex-start;
}

.menuBox-body .product-varient p {
  font-size: 14px;
  margin: 0 1px;
}
.product-specification {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 2% auto;
}
.menuBox-body .product-specification p {
  font-size: 10px;
  margin: 0% 1px;
}
.menuBox-body .product-grid .product-details .cart-qty {
  width: 60px;
  height: 20px;
  display: flex;
  font-size: 12px;
  background: #f0f0f0;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
}

.cart-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 100px - 125px);
  width: 100%;
}

.cart-empty h2 {
  font-size: 16px;
  font-weight: 300;
  margin: 20px;
  margin-bottom: 5px;
}

.cart-empty p {
  font-size: 11px;
  font-weight: 300;
  margin: 5px 10px;
  text-align: center;
  color: #af9aa4;
}

.cart-empty .btn {
  padding: 10px;
  border: none;
  background: #af9aa4;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 10px 20px;
}

.btn:focus {
  outline: none;
}

.menuBox-body .btn {
  margin-top: 20px;
  padding: 10px;
  border: none;
  background: #00c569;
  color: #fff;
  font-size: 20px;
}

.menuBox-body .btn:hover {
  box-shadow: 0px 2px 4px #00c56947;
}

.menuBox-footer {
  position: fixed;
  bottom: 0;
  width: 380px;
  z-index: 9999;
  /* height: 135px; */
  /* border-top: 1px solid #eee; */
  box-shadow: 0px -1px 10px #24242412;
}

.menuBox-footer .checkout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0% auto;
  width: 95%;
  margin-bottom: 5%;
}

.menuBox-footer .btn {
  padding: 10px;
  line-height: 0vw;
  letter-spacing: 0.108vw;
  border: none;
  background: #af9aa4;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 10px 20px;
  margin-top: 0;
  height: 2.75vw;
  cursor: pointer;
}

.checkoutPrice .price p,
.checkoutPrice .price h4 {
  margin: 0;
  color: #929292;
}

.checkoutPrice .price h4 {
  color: #af9aa4;
}

.openCart {
  box-shadow: 0 0px 3px #23232326;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  transition: all 1s ease;
  transform: translateX(0%);
  overflow: scroll;
}
.product-name {
}

@media (max-width: 560px) and (max-width: 760px) {
  .header .section2 .item3 {
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menuBox {
    width: 100%;
    z-index: 9999;
  }
  .menuBox-header {
    height: auto;
  }
  .menuBox-footer {
    width: 100%;
  }
  .menuBox-header {
    height: 7vh;
  }
  .menuBox-footer .btn {
    height: 10.75vw;
  }
  #close {
    top: 5.75vw;
    width: 6.5vw;
    height: 1.25vw;
    z-index: 95;
    display: block;
    cursor: pointer;
    position: fixed;
  }
  #close.is-active span:nth-child(1) {
    transform: rotate(135deg) translate(1.2vw, 0.1vw);
  }
  #close.is-active span:nth-child(3) {
    transform: rotate(45deg) translate(-0.2vw, 0.7vw);
  }
}
.cart-empty .f-bs:hover .f-bs-arrow,
.cart-empty .f-bs:hover .f-bs-line {
  animation: right-left 0.5s ease-in-out forwards;
}
.cart-empty .f-bs:hover .f-bs-label {
  animation: left-right 0.5s ease-in-out forwards;
}
@keyframes right-left {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(150px, 0px);
  }
}
@keyframes left-right {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(-65px, 0px);
  }
}
.cont-btn {
  width: 90%;
  margin: 0 5% 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #232323 !important;
}
.product-qty {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #232323;
  text-align: center;
  font-size: 12px;
  z-index: 9;
  color: #fff;
  position: relative;
  margin-top: -8px;
  margin-left: -5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>